import React from "react"
import { Link } from "gatsby"
import Helmet from 'react-helmet'

import Layout from '../components/Layout';
import Image from "../components/image"
import SEO from "../components/seo"
import AboutMeSnippet from '../components/AboutMeSnippet';

// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site yo.</p>
//     <p>Now go build something great.</p>
//     <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//       <Image />
//     </div>
//     <Link to="/page-2/">Go to page 2</Link>
//   </Layout>
// )

const IndexPage = () => (
  <Layout>
    <AboutMeSnippet />
  </Layout>
);

export default IndexPage
